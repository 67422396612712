import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from '../components/layout';
import { Grid, GridItem } from '../components/Grid';
import PostCard from '../components/Blog/PostCard';
import Pagination from '../components/Blog/Pagination';
import SEO from '../components/seo';

const Header = styled.header`
  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 3.625rem;
  }
  span {
    font-weight: 300;
  }
`;

const BlogSection = styled.div`
  margin-bottom: 2.4375rem;

  a:hover h3 {
    color: #000000;
  }

  p {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 1.437rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    line-height: 1.8125rem;
  }

  &:last-child {
    margin-bottom: 8.3125rem;
  }
`;

const PersonIndexPage = ({ location, data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;
  const {
    name,
    shortBio,
    seoTitle,
    seoDescription
  } = data.contentfulPerson;
  const { humanPageNumber, skip, limit } = pageContext;
  const seoMeta = {
    title: seoTitle || `${name} | Blog | Workast`,
    description: seoDescription ? seoDescription.seoDescription : shortBio
  };
  return (
    <Layout location={location}>
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
      />
      <Header>
        <h2>
          {'Written By | '}
          <span>{name}</span>
        </h2>
      </Header>
      <BlogSection>
        <Grid gridGap="4rem" minCol="290px">
          {posts.slice(skip, limit * humanPageNumber).map(({ node }) => {
            const postTitle = node.title || node.slug;

            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={postTitle}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
        <Pagination context={pageContext} />
      </BlogSection>
    </Layout>
  );
};

PersonIndexPage.propTypes = {
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
            publishDate: PropTypes.string,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              description: PropTypes.string,
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }),
        })
      ),
    }).isRequired,
    contentfulPerson: PropTypes.shape({
      name: PropTypes.string,
      shortBio: PropTypes.shape({
        shortBio: PropTypes.string,
      }),
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string
      }),
      slug: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    previousPagePath: PropTypes.string.isRequired,
    nextPagePath: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    paginationPath: PropTypes.string.isRequired,
    humanPageNumber: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
};

export default PersonIndexPage;

export const pageQuery = graphql`
  query PersonPostBySlug($slug: String!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { author: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      name
      shortBio {
        shortBio
      }
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`;
